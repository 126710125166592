<template>
    <layout>
        <page-header :title="title" :items="items" />

        <div class="row">
            <div class="col-sm-12 mb-2 text-right">
                <button
                    class="btn btn-success"
                    @click="saveChanges"
                >
                    <i class="fas fa-save mr-1" />
                    Save Changes
                </button>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 col-xl-6 d-flex">
                <div class="card-box flex-fill">
                    <h5 class="text-uppercase py-2 mt-0">
                        <i class="fe-user mr-1" />
                        User Data
                    </h5>
                    <div class>
                        <h4 class="font-13 text-muted text-uppercase">
                            Order Number :
                        </h4>
                        <p class="mb-3">
                            {{ orderProductColor.order.order_number }}
                        </p>
                        <h4 class="font-13 text-muted text-uppercase">
                            Order Date :
                        </h4>
                        <p class="mb-3">
                            {{ orderProductColor.order.created_at | formatDate }}
                        </p>
                        <h4 class="font-13 text-muted text-uppercase">
                            Customer Name :
                        </h4>
                        <p class="mb-3">
                            {{
                                `${orderProductColor.order.customer.first_name}
                                ${orderProductColor.order.customer.last_name}`
                            }}
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-md-12 col-xl-6 d-flex">
                <div class="card-box flex-fill">
                    <h5 class="text-uppercase py-2 mt-0">
                        <i class="fe-map-pin mr-1" />
                        Tracking and Scan
                    </h5>
                    <h4 class="font-13 text-muted text-uppercase">
                        Outgoing Tracking :
                    </h4>
                    <div class="form-group d-flex mb-1">
                        <input
                            v-model="orderProductColor.outgoing_tracking"
                            class="form-control form-control-sm bg-light border-0"
                            type="text"
                        />
                    </div>
                    <div class="form-group d-flex mb-input">
                        <b-form-checkbox
                            v-model="allInOrderFields.outgoing_tracking"
                            class="text-muted checkbox-success ml-2"
                        >
                            Change for all products in order
                        </b-form-checkbox>
                    </div>
                    <h4 class="font-13 text-muted text-uppercase">
                        Incoming Tracking :
                    </h4>
                    <div class="form-group d-flex mb-1">
                        <input
                            v-model="orderProductColor.incoming_tracking"
                            class="form-control form-control-sm bg-light border-0"
                            type="text"
                        />
                    </div>
                    <div class="form-group d-flex mb-input">
                        <b-form-checkbox
                            v-model="allInOrderFields.incoming_tracking"
                            class="text-muted checkbox-success ml-2"
                        >
                            Change for all products in order
                        </b-form-checkbox>
                    </div>
                    <h4 class="font-13 text-muted text-uppercase">
                        3D Scan:
                    </h4>
                    <div class="form-group d-flex mb-1">
                        <input
                            v-model="orderProductColor.scan_link"
                            class="form-control form-control-sm bg-light border-0"
                            type="text"
                        />
                    </div>
                    <div class="form-group d-flex mb-input">
                        <b-form-checkbox
                            v-model="allInOrderFields.scan_link"
                            class="text-muted checkbox-success ml-2"
                        >
                            Change for all products in order
                        </b-form-checkbox>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-box">
            <h5 class="p-2 m-0 mb-2 text-uppercase">
                Logistics Status
            </h5>
            <div class="d-flex flex-wrap">
                <div class="form-group col-lg-6">
                    <b-form-select
                        v-model="orderProductColor.status"
                        :options="statuses"
                    />
                </div>
                <div
                    class="form-group col-lg-6 d-flex align-items-end"
                >
                    <button
                        type="button"
                        class="btn btn-danger waves-effect waves-light ml-1"
                        @click="changeStatus(false)"
                    >
                        Change
                    </button>
                    <button
                        type="button"
                        class="btn btn-danger waves-effect waves-light ml-2"
                        @click="changeStatus(true)"
                    >
                        Change For All Order Items
                    </button>
                </div>
            </div>
            <order-history-table
                :histories="orderProductColor.history"
            />
        </div>
    </layout>
</template>

<script>
import { mapActions } from 'vuex';
import appConfig from '@src/app.config';
import OrderHistoryTable from '@components/orders/OrderHistoryTable';

export default {
    page: {
        title: 'Logistics Center Details',
        meta: [{ name: 'description', content: appConfig.description }]
    },

    components: {
        OrderHistoryTable
    },

    data() {
        return {
            title: 'Logistics Center Details',
            items: [
                {
                    text: 'Upstep',
                    to: '/'
                },
                {
                    text: 'Logistics Center',
                    to: '/logistics-center'
                },
                {
                    text: 'Details',
                    active: true
                }
            ],
            orderProductColor: {
                order: {
                    customer: {}
                },
                history: []
            },
            statuses: [
                { text: '- Status -', disabled: true },
                'ORDER RECEIVED',
                'SENT IMPRESSION KIT',
                'SCANNED & READY FOR DESIGN',
                'HOLD',
                'ADDRESS VERIFICATION',
                'RTS'
            ],
            allInOrderFields: {}
        };
    },

    mounted() {
        this.orderProductColorId = this.$route.params.id;
        this.fetchOrderProductColor();
    },

    methods: {
        ...mapActions({
            getOrderProductColor: 'orderProductColors/show',
            updateOrderProductColor: 'orderProductColors/update',
            changeOrderProductColorStatus: 'orderProductColors/changeStatus'
        }),

        async fetchOrderProductColor() {
            try {
                this.orderProductColor = await this.getOrderProductColor(
                    this.orderProductColorId
                );
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        async saveChanges() {
            try {
                await this.updateOrderProductColor({
                    id: this.orderProductColorId,
                    outgoing_tracking: this.orderProductColor.outgoing_tracking,
                    incoming_tracking: this.orderProductColor.incoming_tracking,
                    scan_link: this.orderProductColor.scan_link,
                    allInOrderFields: this.allInOrderFields
                });

                this.$toaster('Changes have been saved!');
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        async changeStatus(allInOrder) {
            try {
                let updateScanDate = false;

                if (
                    this.orderProductColor.status ===
                        'SCANNED & READY FOR DESIGN' &&
                    this.orderProductColor.scan_date
                ) {
                    const confirmed = await this.$confirmationModal(
                        'This item already have a scan date. Would you like to change it?'
                    );

                    if (confirmed) {
                        updateScanDate = true;
                    }
                }

                const newStatus = await this.changeOrderProductColorStatus({
                    id: this.orderProductColorId,
                    status: this.orderProductColor.status,
                    allInOrder,
                    updateScanDate
                });

                this.$toaster('Status has been updated!');

                this.orderProductColor.history = [
                    newStatus,
                    ...this.orderProductColor.history
                ];
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.mb-input {
    margin-bottom: 14px;
}

.btn-change-all {
    min-width: 110px;
}
</style>
